import React, {useEffect} from "react";
import FullBagService from "../../services/fullBag";
import CartService from "../../services/cart";
import PXGFullBagButton from "../shared/forms/button/PXGFullBagButton";
import Modal from "../shared/modal/Modal";
import useModal from "../shared/modal/useModal";
import gaHelpers from "../../services/googleAnalytics/gaHelpers";
import {useRouter} from "next/router";

function ContinueModal(props) {
	const {locale} = useRouter();
	const {localeResources, show} = props;

	const {
		showModal: showContinueModal,
		triggerElement: triggerContinueModal,
		toggle: toggleContinueModal,
	} = useModal();

	useEffect(() => {
		if (show) {
			toggleContinueModal();
		}
	}, [show]);

	function StartOver() {
		CartService.getOrderItems(undefined, locale).then((results) => {
			if (results && results.length) {
				gaHelpers.pushRemoveFromCartEventToGA(results);
			}
			gaHelpers.pushRestartEventToGA();
			FullBagService.clearFullBag().then(function (response) {
				//setShowContinueModal(false);
				toggleContinueModal();
			});
		});
	}

	return (
		<Modal showModal={showContinueModal} triggerElement={null} toggle={toggleContinueModal} showCloseButton={false}>
			<div className="text-center">
				<h3>Welcome back!</h3>
				<p>It looks like you have already started to build a bag.</p>
				<p>Would you like to continue or start over?</p>
				<div>
					<PXGFullBagButton
						theme="filledBlack"
						layoutClasses="mx-1"
						onClick={() => (window.location = "/fitting")}
						copy={localeResources.continue}
					/>
					<PXGFullBagButton
						theme="filledBlack"
						layoutClasses="mx-1"
						onClick={(event) => StartOver()}
						copy={localeResources.startOver}
					/>
				</div>
			</div>
		</Modal>
	);
}
export default ContinueModal;
